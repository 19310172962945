<template>
  <div
    class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
  >
    <DataTable
      class="col-12"
      :value="tableDataWithoutTotals"
      responsiveLayout="scroll"
      :lazy="false"
      :loading="loading"
      showGridlines
      :paginator="false"
      :row-class="rowClass"
    >
      <template #header>
        <div class="flex align-items-center justify-content-between">
          <div>
            <h3>Mailing Details</h3>
          </div>
          <div class="flex align-items-center">
            <OverlayPanel ref="op" appendTo="body">
              <div
                v-for="optionalColumn in allColumns"
                :key="optionalColumn.field"
                class="flex-1 px-2 py-2"
              >
                <Checkbox
                  name="city"
                  :value="optionalColumn"
                  :binary="true"
                  v-model="optionalColumn.toggle"
                />
                <label class="pl-2">{{ optionalColumn.header }}</label>
              </div>
            </OverlayPanel>
            <div class="flex flex-column justify-content-center">
              <Dropdown
                v-model="sortBySelected"
                :options="sortByOptions"
                option-value="field"
                option-label="label"
              />
            </div>
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="toggle"
              icon="pi pi-eye"
              aria:haspopup="true"
              aria-controls="overlay_panel"
            />
          </div>
        </div>
      </template>
      <Column field="name" header="Name" :sortable="true"></Column>
      <Column
        v-for="column in selectedColumns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :sortable="column.sortable"
        class="text-right"
        ><template #body="slotProps">
          {{ $filters.numberThousandSeparator(slotProps.data[column.field]) }}
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row v-if="totals" row-class="font-bold">
          <Column footer="Totals"></Column>
          <Column
            v-for="column in selectedColumns"
            :key="column.field"
            :footer="$filters.numberThousandSeparator(totals[column.field])"
            footerStyle="text-align: right;"
          />
        </Row>
      </ColumnGroup>
    </DataTable>
  </div>
</template>

<style>
.p-datatable .p-datatable-thead > tr > th:not(:first-of-type) {
  justify-content: flex-end !important;
  text-align: right !important;
}

span.p-dropdown-label.p-inputtext {
  padding: 0.625rem 1rem;
}
</style>

<script>
import MailingTable from "@/models/MailingTable";
export default {
  name: "MailingDetailTable",
  data() {
    return {
      mailingData: [],
      sortBySelected: null,
      sortByOptions: [],
      allColumns: [
        {
          key: "contacts",
          header: "Contacts",
          sortable: false,
          field: "contacts",
          toggle: true,
        },
        {
          key: "total_leads",
          header: "Total Leads",
          sortable: false,
          field: "totalLeads",
          toggle: true,
        },
        {
          key: "set_counter",
          header: "Set",
          sortable: false,
          field: "setCounter",
          toggle: true,
        },
        {
          key: "issued_counter",
          header: "Issued",
          sortable: false,
          field: "issuedCounter",
          toggle: true,
        },
        {
          key: "sale_counter",
          header: "Sales",
          sortable: false,
          field: "saleCounter",
          toggle: true,
        },
        {
          key: "total_sales_amount",
          header: "Total Sales",
          sortable: false,
          field: "totalSalesAmount",
          toggle: true,
        },
      ],
      totalRecords: 0,
      totalRow: [],
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    sortBySelected() {
      this.loadData();
      console.log(this.totals);
    },
  },
  computed: {
    tableData() {
      if (!this.mailingData || !this.mailingData.stats) return [];

      let tableData = [];

      for (let d of this.mailingData.stats.mailed.total) {
        let funnel = this.mailingData.stats.funnel_counts.filter((data) => {
          return data.key_name === d.key_name;
        });
        let funnel_counts = funnel[0];

        let td = {
          name: d.key_name,
          contacts: d.agg,
          totalLeads: (funnel_counts && funnel_counts.total_leads) || 0,
          setCounter: (funnel_counts && funnel_counts.set_counter) || 0,
          issuedCounter: (funnel_counts && funnel_counts.issued_counter) || 0,
          saleCounter: (funnel_counts && funnel_counts.sale_counter) || 0,
          totalSalesAmount:
            (funnel_counts && funnel_counts.total_sales_amount) || 0,
        };

        tableData.push(td);
      }

      tableData.push({
        name: "Total",
        total: true,
        contacts: tableData.reduce((a, b) => a + (b["contacts"] || 0), 0),
        totalLeads: tableData.reduce((a, b) => a + (b["totalLeads"] || 0), 0),
        setCounter: tableData.reduce((a, b) => a + (b["setCounter"] || 0), 0),
        issuedCounter: tableData.reduce(
          (a, b) => a + (b["issuedCounter"] || 0),
          0
        ),
        saleCounter: tableData.reduce((a, b) => a + (b["saleCounter"] || 0), 0),
        totalSalesAmount: this.$filters.numberThousandSeparator(
          tableData.reduce((a, b) => a + (b["totalSalesAmount"] || 0), 0)
        ),
      });

      return tableData;
    },
    tableDataWithoutTotals() {
      return this.tableData.filter((x) => {
        return x.name != "Total";
      });
    },
    totals() {
      let totalarray = this.tableData.filter((x) => {
        return x.name == "Total";
      });
      return totalarray[0];
    },
    selectedColumns() {
      return this.allColumns.filter((item) => item.toggle);
    },
  },
  methods: {
    loadData() {
      this.loading = true;

      let mailingTable = this.sortBySelected
        ? MailingTable.params({ data_field: this.sortBySelected })
        : MailingTable;

      mailingTable
        .find(this.$route.params.id)
        .then((response) => {
          this.mailingData = response.data;
          console.log("MailingData");
          console.log(this.mailingData);
          this.sortByOptions = response.data.reportable_fields;
          this.sortBySelected = response.data.selected_field.field_name;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    rowClass(data) {
      return data.total ? "font-bold" : null;
    },
  },
};
</script>
